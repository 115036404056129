<template>
  <div>

    <join-card heading="Join to Retina"  :include-name="true">

      <status-message :response="response" timeout="6000"></status-message>

      <form class="w-full" action="" @submit.prevent="submitForm">
        <div class="my-6 w-full">
          <input
            type="text"
            autocomplete="off"
            placeholder="Enter phone number : 017XXXXXXXX"
            class="
              text-center
              block
              w-full
              max-w-xs
              mx-auto
              border-2
              rounded-lg
              focus:outline-none
              p-2
            "
            v-model="phone"
            ref="phone"
          />

          <show-error
            :errors="formErrors"
            name="phone"
            class="w-full max-w-xs mx-auto text-center"
          />
        </div>

        <div class="flex justify-center">
          <button
            type="submit"
            class="px-6 py-1 text-xl bg-blue-600 text-white rounded shadow-sm flex items-center"
          >
            <spinner v-if="loading" />
            <span>Next</span>
          </button>
        </div>
      </form>
    </join-card>
  </div>
</template>

<script>
import JoinCard from "../components/joincard.vue";
import ShowError from "../../../../components/show-error";
import Spinner from "../../../../components/spinner";
import StatusMessage from "../../../../components/status-message";

export default {
  components: {StatusMessage, Spinner, ShowError, JoinCard },
  name: "Join",
  data: function () {
    return {
      phone: "",
      loading: false,
      response: {}
    };
  },
  mounted() {
    this.focusInput();
  },
  methods: {
    submitForm() {
      this.loading = true;
      this.formErrors = null;

      this.$store.dispatch("student/join", {
        data: { phone: this.phone },
        error: ( res ) => {
          if( typeof res.data == 'object' ) {
            this.formMessage = res.data.message;
          }
        },
        has_errors: (errors) => (this.formErrors = errors),
        response: res => this.response = res,
        success: (response) => {
          const data = response.data;
          if (data.has_user) {
            this.$router.push({
              path: "/login",
              query: { phone: data.phone },
            });
          } else {
            this.$router.push({
              path: "/confirm",
              query: { phone: data.phone },
            });
          }
        },
      }).finally( (  ) => {
        this.loading = false
      });

    },
    focusInput() {
      this.$refs.phone.focus();
    },
  },
};
</script>

